import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About Us" />
      Details about the organization go here.
    </Layout>
  );
};

export default AboutPage;
